import { create } from "zustand";

export type ModalType = {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
};

const createModalStore = () =>
	create<ModalType>((set) => ({
		isOpen: false,
		onOpen: () => set({ isOpen: true }),
		onClose: () => set({ isOpen: false }),
	}));

export const useChatInterfaceModal = createModalStore();
export const useAllSourcesModal = createModalStore();
export const useCitationModal = createModalStore();