import React from "react";
import PromptBox from "../promptBox/PromptBox";
import useBrand from "../../store/brandStore";
import { BrandState } from "../../store/brandStore";
import { getContrastColor } from "../../utils/getContrastColor";
import {
	Welcome,
	Top,
	GreetingContainer,
	Greeting,
	SpanGreeting,
	Bottom,
	SuggestedPrompts,
	RandomPromptsContainer,
	RandomPrompt,
	PromptBoxHome,
} from "./StyledComponent";

interface EntryPageProps {
	firstName?: string;
	randomPrompts: string[];
	setText: (text: string) => void;
	textareaRef: React.RefObject<HTMLTextAreaElement>;
	text: string;
	generating: boolean;
	sendMessage: () => void;
	handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	sendDisabled: string | React.ReactNode;
	stopStream: () => void;
	stop?: string | React.ReactNode;
}

const EntryPage = ({
	firstName,
	setText,
	textareaRef,
	text,
	generating,
	sendMessage,
	handleChange,
	stopStream,
}: EntryPageProps) => {
	const { brandColor, quickPrompts, loading } = useBrand((state: BrandState) => state);

	return (
		<Welcome>
			<Top>
				<GreetingContainer>
					<Greeting style={{ color: getContrastColor(brandColor) }}>
						Hi {firstName},
					</Greeting>
					<SpanGreeting style={{ color: getContrastColor(brandColor) }}>
						{" "}
						I'm an AI assistant trained to transform your business by delivering
						real-time insights and actionable data with precision.
					</SpanGreeting>
				</GreetingContainer>
			</Top>

			<Bottom>
				{loading ? (
					""
				) : (
					<>
						{quickPrompts.length > 0 ? (
							<div>
								<SuggestedPrompts style={{ marginBottom: "16px" }}>
									Quick Prompts
								</SuggestedPrompts>
								<RandomPromptsContainer>
									{quickPrompts?.map((itm: string, idx: number) => {
										return (
											<RandomPrompt
												key={idx}
												onClick={() => {
													setText(itm);
													textareaRef.current?.focus();
												}}
											>
												{itm}
											</RandomPrompt>
										);
									})}
								</RandomPromptsContainer>
							</div>
						) : (
							""
						)}
					</>
				)}

				<PromptBoxHome>
					<PromptBox
						text={text}
						generating={generating}
						sendMessage={sendMessage}
						stopStream={stopStream}
						handleChange={handleChange}
						textareaRef={textareaRef}
					/>
				</PromptBoxHome>
			</Bottom>
		</Welcome>
	);
};

export default EntryPage;
