import React, { useEffect, useRef, useCallback } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import SyntaxHighlighterWrapper from "./forwardRefs";
import { preprocessChildren } from "../utils/replaceMarkDown";
import TypewriterEffect from "./TypewriterEffect";
import { useCitationModal } from "./usePopUpModals";
import { useCitationStore } from "../store/citationStore";
import { ModalType } from "./usePopUpModals";
import { CitationState } from "../store/citationStore";
import useBrand, { BrandState } from "../store/brandStore";
import { getContrastColor } from "../utils/getContrastColor";
import { Paragraph, SnapSummary } from "../components/chatPopUp/StyledComponent";

const UseMarkdown = ({
	children,
	ClassName = "",
	streaming = false,
	Messages,
	index,
}: {
	children: string;
	ClassName?: string;
	streaming?: boolean;
	Messages: any;
	index: number;
}) => {
	const { secondaryColor } = useBrand((state: BrandState) => state);
	const { onOpen } = useCitationModal((state: ModalType) => state);
	const { setCitation } = useCitationStore((state: CitationState) => state);

	const isSnapSummary = typeof children === "string" && children.startsWith("<snap_summary>");

	console.log(isSnapSummary, index, "oppp");

	const processedChildren =
		typeof children === "string"
			? streaming
				? preprocessChildren(children.replace(/^###\s*/gm, ""))
				: preprocessChildren(children)
			: children;

	const hasCodeBlocks = /```/.test(children ?? "");
	const chartRef = useRef<HTMLDivElement | null>(null);
	const chartMatch = children.match(/<code_analyse>([\s\S]*?)<\/code_analyse>/);

	const formatCodeBlock = useCallback((code: string) => {
		return code?.replace(/\\n/g, "\n")?.replace(/\\"/g, '" ')?.trim();
	}, []);

	const getCitationContent = (citationKey: string) => {
		// Ensure the message exists at the specified index.
		const message = Messages[index];
		if (!message || !message.responses) return null;

		// Only search within the current message's responses.
		for (const response of message.responses) {
			const citation = response.citations?.find((cit: any) => cit[citationKey]);
			if (citation) {
				return {
					source: citation[citationKey]?.source_id_content,
					url: citation[citationKey]?.url,
					title: citation[citationKey]?.title,
				};
			}
		}
		return null;
	};

	const decodeHtmlEntities = (str: string): string => {
		const textarea = document.createElement("textarea");
		textarea.innerHTML = str;
		return textarea.value;
	};

	const extractAndReplaceImages = (content: string) => {
		const imageRegex = /<image\s+src="([^"]+?)"\s*\/>/g;
		const images: string[] = [];
		const decodedContent = decodeHtmlEntities(content).replace(/\\n/g, "\n");
		const preprocessedContent = decodedContent.replace(/<em>|<\/em>/g, "_");

		let match;
		while ((match = imageRegex.exec(preprocessedContent)) !== null) {
			const url = match[1].trim();
			try {
				new URL(url);
				images.push(url);
			} catch {
				//
			}
		}

		const contentWithoutImages = preprocessedContent
			.replace(imageRegex, "")
			.replace(/(<br\s*\/?>)+/g, "<br><br>");
		return { images, contentWithoutImages };
	};

	const renderImagesAsStyledGrid = (images: string[]) => {
		if (images.length === 0) return null;

		const isOdd = images.length % 2 !== 0;
		return (
			<div className={`image-container ${isOdd ? "odd-count" : ""}`}>
				{images.map((url, index) => {
					const isLastAndOdd = isOdd && index === images.length - 1;
					const className = isLastAndOdd ? "full-width" : "square";
					return (
						<div key={index} className={`image-est90 ${className}`}>
							<img src={url} alt={`Image ${index + 1}`} className="image-exe-tro" />
						</div>
					);
				})}
			</div>
		);
	};

	useEffect(() => {
		const renderChart = () => {
			if (chartMatch && chartRef.current) {
				try {
					chartRef.current.innerHTML = "";
					const scriptContent = formatCodeBlock(chartMatch[1]);
					const parser = new DOMParser();
					const doc = parser.parseFromString(scriptContent, "text/html");
					const canvas = doc.querySelector("canvas");
					const scripts = doc.querySelectorAll("script");
					if (canvas) chartRef.current.appendChild(canvas);
					scripts.forEach((script) => {
						if (script.textContent) {
							const executeScript = new Function(script.textContent);
							executeScript();
						}
					});
				} catch (error) {
					//
				}
			}
		};

		renderChart();
	}, [chartMatch, formatCodeBlock]);

	const { images, contentWithoutImages } = extractAndReplaceImages(processedChildren as string);

	if (images.length > 0)
		return (
			<>
				<TypewriterEffect text={contentWithoutImages} speed={4} streaming={streaming} />
				{renderImagesAsStyledGrid(images)}
				<div ref={chartRef}></div>
			</>
		);

	const renderText = (text: any) => {
		if (typeof text !== "string" || !text) {
			return text;
		}
		const citationPattern = /\[{1,2}(\d+)\]{1,2}/g;
		const parts = text.split(citationPattern);

		return parts.map((part, index) => {
			if (index % 2 === 0) {
				return part;
			} else {
				return (
					<button
						key={index}
						className=""
						onMouseEnter={(e) => {
							const timer = setTimeout(() => {
								const content = getCitationContent(part);
								if (content) {
									setCitation({
										source: content.source,
										url: content.url,
										title: content.title,
									});
									onOpen();
								}
							}, 500);
							(e.currentTarget as any)._hoverTimer = timer;
						}}
						onMouseLeave={(e) => {
							const timer = (e.currentTarget as any)._hoverTimer;
							if (timer) {
								clearTimeout(timer);
								(e.currentTarget as any)._hoverTimer = null;
							}
						}}
						style={{
							color: getContrastColor(`#${secondaryColor}`),
							cursor: "pointer",
							padding: "5px",
							fontSize: "12px",
							backgroundColor: `#${secondaryColor}`,
							borderRadius: "100%",
							width: "23px",
							height: "23px",
						}}
					>
						{part}
					</button>
				);
			}
		});
	};

	if (isSnapSummary) {
		return (
			<SnapSummary>
				<h3>Summary</h3>
				<TypewriterEffect text={contentWithoutImages.replace(/<snap_summary>|<br\s*\/?>/g, "")} speed={4} streaming={streaming} />
			</SnapSummary>
		);
	}

	return (
		<div className={ClassName}>
			{hasCodeBlocks || !hasCodeBlocks ? (
				<>
					<Markdown
						children={formatCodeBlock(children)}
						className={ClassName}
						components={{
							p: ({ children }) => (
								<Paragraph>{renderText(children as string)}</Paragraph>
							),
							code({ node, ...props }) {
								const match = /language-(\w+)/.exec(props.className || "");
								return match ? (
									<SyntaxHighlighterWrapper {...props} language={match[1]}>
										{props.children}
									</SyntaxHighlighterWrapper>
								) : (
									<code
										{...props}
										className={props.className}
										style={{
											backgroundColor: "#f5f5f5",
											padding: "2px 4px",
											borderRadius: "4px",
											fontFamily: "monospace",
											fontSize: "13px",
											color: "#555",
											fontStyle: "italic",
											whiteSpace: "pre-wrap",
											wordBreak: "break-word",
										}}
									>
										{props.children}
									</code>
								);
							},
							...Array.from({ length: 6 }, (_, i) => {
								const Tag = `h${i + 1}` as keyof JSX.IntrinsicElements;
								return {
									[Tag]: ({ node, children, ...props }: any) => (
										<Tag {...props} style={{ marginBlock: "10px" }}>
											{children}
										</Tag>
									),
								};
							}).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
							ul: ({ node, ...props }) => (
								<ul
									{...props}
									style={{
										marginTop: "10px",
										marginBottom: "10px",
										display: "flex",
										flexDirection: "column",
										gap: "1rem",
									}}
								/>
							),
							ol: ({ node, ...props }) => (
								<ol
									{...props}
									style={{
										marginTop: "10px",
										marginBottom: "5px",
										display: "flex",
										flexDirection: "column",
										gap: "1rem",
									}}
								/>
							),

							li: ({ node, ...props }) => (
								<li
									{...props}
									style={{
										color: "#444444",
										fontSize: "14px",
										fontWeight: "400",
									}}
								/>
							),
						}}
						remarkPlugins={[remarkGfm, remarkBreaks]}
					/>
				</>
			) : (
				<>
					<TypewriterEffect text={contentWithoutImages} speed={4} streaming={streaming} />
					{renderImagesAsStyledGrid(images)}
					<div ref={chartRef}></div>
				</>
			)}
		</div>
	);
};

export default UseMarkdown;
