import React from "react";
import GeneralModal from "../GeneralModal";
import { ModalType, useAllSourcesModal } from "../../../../hooks/usePopUpModals";
import { useSourcesStore } from "../../../../store/sourcesStore";
import { SourcesState, Source } from "../../../../store/sourcesStore";
import styled from "styled-components";


const AllSources = () => {
	const { isOpen, onClose } = useAllSourcesModal((state: ModalType) => state);
	const { sources: RenderSources } = useSourcesStore((state: SourcesState) => state);
	const sources: Source[] = RenderSources;

	const MainContent = styled.main`
		height: 500px;
		overflow-y: auto;
		padding-block: 15px;
		scrollbar-width: none;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			display: none;
		}
	`;

	const SourcesContainer = styled.div`
		display: flex;
		flex-direction: column;
		gap: 14px;
	`;

	const SourceCard = styled.div`
		width: 100%;
		border: 1px solid #eaeaea;
		padding: 11px;
		border-radius: 8px;
		font-size: 14px;
	`;

	const content = (
		<MainContent>
			<SourcesContainer>
				{sources &&
					sources.map((source) => (
						<SourceCard
							role="button"
							onClick={() => window.open(source.link, "_blank")}
							key={source.link}
							style={{ padding: "10px" }}
							onMouseEnter={(e: React.MouseEvent<HTMLDivElement>) => {
								Object.assign(e.currentTarget.style, {
									background: "#F5F7F6",
									cursor: "pointer",
								});
							}}
							onMouseLeave={(e: React.MouseEvent<HTMLDivElement>) => {
								Object.assign(e.currentTarget.style, {
									background: "initial",
									transition: "all 0.3s ease",
								});
							}}
						>
							<div style={{ display: "flex", gap: "8px" }}>
								{source.favicon && (
									<img
										src={source?.favicon || ""}
										alt="favicon"
										width={20}
										height={20}
										style={{ borderRadius: "50%" }}
									/>
								)}
								<p
									style={{
										color: "#000",
										fontSize: "14px",
										fontWeight: "600",
										textDecoration: "underline",
										fontFamily: "CircularStd",
									}}
								>
									{source?.title}
								</p>
							</div>
							<p
								style={{
									color: "#6D6A6A",
									fontSize: "14px",
									fontFamily: "CircularStd",
								}}
							>
								{source?.snippet}
							</p>
						</SourceCard>
					))}
			</SourcesContainer>
		</MainContent>
	);

	return <GeneralModal title="All Sources" isOpen={isOpen} onClose={onClose} content={content} />;
};

export default AllSources;
