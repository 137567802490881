import React from "react";
import ChatInterface from "./components/chatInterface/ChatInterface";
import ChatPopUp from "./components/chatPopUp/ChatPopUp";
import "./App.css";
import { globalStyles } from "./globbalStyle";

export interface Props {
	client_id?: string;
	user_id?: string;
	quick_prompts?: string[];
	theme?: string;
	first_name?: string;
	meta_data?: {};
	session_id?: string;
	button_size?: string;
}

function App({
	client_id,
	theme,
	quick_prompts,
	first_name,
	meta_data,
	session_id,
	button_size,
	user_id,
}: Props) {
	return (
		<div className="app">
			<style>{globalStyles()}</style>
			<ChatInterface button_size={button_size} theme={theme} client_id={client_id} />
			<ChatPopUp
				client_id={client_id}
				user_id={user_id ?? " "}
				quick_prompts={quick_prompts}
				first_name={first_name}
				meta_data={meta_data}
				session_id={session_id}
			/>
		</div>
	);
}

export default App;
