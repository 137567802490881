import axios from "axios";
import React, { useEffect, useCallback } from "react";
import styles from "./ChatInterface.module.scss";
import { useChatInterfaceModal } from "../../hooks/usePopUpModals";
import { ModalType } from "../../hooks/usePopUpModals";
import { chatInterfaceStyle } from "../../injectedStyles/chatInterfaceStyle";
import { BrandState } from "../../store/brandStore";
import useBrand from "../../store/brandStore";
import useChat from "../../store/chatStore";
import { ChatState } from "../../store/chatStore";
const CDN_URL = process.env.REACT_APP_CDN_URL;
const API_URL = process.env.REACT_APP_API_URL;
const ast = process.env.REACT_APP_AST;
const pxa = process.env.REACT_APP_PXA;

export interface Props {
	client_id?: string;
	quick_prompts?: string[];
	theme?: string;
	first_name?: string;
	meta_data?: {};
	button_size?: string;
}

const ChatInterface = ({ button_size, client_id }: Props) => {
	const { setSessionId, sessionId } = useChat((state: ChatState) => state);
	const { setLoading, setBrandLogo, setBrandColor, setSecondaryColor, setQuickPrompts } =
		useBrand((state: BrandState) => state);
	const { onOpen } = useChatInterfaceModal((state: ModalType) => state);

	const fetchBrandDetails = useCallback(async () => {
		setLoading(true);
		try {
			const response = await axios.get(`${API_URL}/partners/brand-details/${client_id}`);
			if (response.status === 200) {
				setBrandLogo(response.data.logo);
				setBrandColor(response.data?.primaryColor ?? "29645B");
				setSecondaryColor(response.data?.secondaryColor ?? "000");
				setQuickPrompts(response.data?.samplePrompts ?? []);
			}
		} catch (error) {
			setBrandLogo(null);
			setBrandColor("29645B");
			setSecondaryColor("29645B");
			setQuickPrompts([]);
		} finally {
			setLoading(false);
		}
	}, [client_id, setBrandColor, setBrandLogo, setLoading, setSecondaryColor, setQuickPrompts]);

	const launchConversation = useCallback(async () => {
		try {
			const basicAuth = "Basic " + btoa(ast + ":" + pxa);
			const response = await fetch(`${API_URL}/partner/launch-conversation`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: basicAuth,
				},
				body: JSON.stringify({
					partnerId: client_id ?? "-",
				}),
			});

			if (response.ok) {
				const data = await response.json();
				setSessionId(data?.sessionId ?? "a-random-id");
			}
		} catch (error) {
			//
		}
	}, [client_id, setSessionId]);

	useEffect(() => {
		fetchBrandDetails();
	}, [client_id, fetchBrandDetails]);

	// Expose window methods for external control
	useEffect(() => {
		(window as any).openSnapshot = () => {
			onOpen();
		};

		(window as any).fetchBrandDetails = () => {
			fetchBrandDetails();
		};
		// Cleanup on unmount
		return () => {
			delete (window as any).openSnapshot;
			delete (window as any).fetchBrandDetails;
		};
	}, [onOpen, fetchBrandDetails]);

	const handleTriggerClick = () => {
		onOpen();
		if (!sessionId) {
			launchConversation();
		}
	};

	return (
		<main className={`main ${styles.main}`}>
			<style>{chatInterfaceStyle()}</style>
			<button
				onClick={handleTriggerClick}
				className={`trigger ${styles.trigger} ${
					button_size === "sm"
						? styles.sm
						: button_size === "md"
						? styles.md
						: button_size === "lg"
						? styles.lg
						: ""
				}`}
			>
				<img
					src={`${CDN_URL}/trigger.svg`}
					alt="trigger"
					className={`triggerImg ${styles.triggerImg}`}
				/>
			</button>
		</main>
	);
};

export default ChatInterface;
