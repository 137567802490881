import React, { useEffect, useRef } from "react";
import SVGIcon from "../chatPopUp/svgs";
import useBrand, { BrandState } from "../../store/brandStore";
import styled from "styled-components";
import {
	ButtonMain,
	TextArea,
	TextAreaInput,
	ActionBtns,
	SendBtn,
	StopBtn,
} from "./StyledComponent";

interface Props {
	text: string;
	generating: boolean;
	sendMessage: () => void;
	stopStream: () => void;
	handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	textareaRef: React.RefObject<HTMLTextAreaElement>;
}

const PromptBox = ({ text, generating, sendMessage, stopStream, handleChange }: Props) => {
	const icons = SVGIcon();
	const { secondaryColor } = useBrand((state: BrandState) => state);
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = "auto";
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	}, [text, textareaRef]);

	return (
		<ButtonMain>
			<TextArea>
				<TextAreaInput
					rows={1}
					id="prompt_box"
					ref={textareaRef}
					onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
						if (
							e.key === "Enter" &&
							!e.shiftKey &&
							text.trim().length > 0 &&
							!generating
						) {
							e.preventDefault();
							sendMessage();
						}
					}}
					value={text}
					placeholder={"Ask Snapshot Anything"}
					style={{ fontFamily: "CircularStd, sans-serif" }}
					onChange={handleChange}
				/>
				<ActionBtns>
					{!generating && (
						<SendBtn
							textLength={text.trim().length}
							disabled={text.trim().length < 1}
							onClick={sendMessage}
							style={{ background: `#${secondaryColor}` || "#3F8D81" }}
						>
							{icons.sendDisabled}
						</SendBtn>
					)}

					{generating && (
						<StopBtn
							onClick={stopStream}
							style={{ background: `#${secondaryColor}` || "#3F8D81" }}
						>
							{icons.stop}
						</StopBtn>
					)}
				</ActionBtns>
			</TextArea>
		</ButtonMain>
	);
};

export default PromptBox;
