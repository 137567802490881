import { create } from "zustand";

export interface CitationState {
  citation: {
    source: string;
    url: string;
    title:string
  } | null;
  setCitation: (citation: { source: string; url: string; title:string } | null) => void;
  clearCitation: () => void;
}

export const useCitationStore = create<CitationState>((set) => ({
  citation: null,
  setCitation: (citation) => set({ citation }),
  clearCitation: () => set({ citation: null })
}));
