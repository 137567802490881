import React from "react";
import { useCitationStore } from "../../../../store/citationStore";
import { useCitationModal } from "../../../../hooks/usePopUpModals";
import { ModalType } from "../../../../hooks/usePopUpModals";
import { CitationState } from "../../../../store/citationStore";
import GeneralModal from "../GeneralModal";
import { allSourcesStyleInject } from "../allSources/allSourcesStyle";
import styles from "./Citation.module.scss";

const Citation = () => {
	const { citation } = useCitationStore((state: CitationState) => state);
	const { isOpen, onClose } = useCitationModal((state: ModalType) => state);

	const content = (
		<main className={`${styles.main}`}>
			<style>{allSourcesStyleInject()}</style>
			<section className={`${styles.citationContainer} mainCitation`}>
				<p>{citation?.source}</p>
			</section>
			<section
				style={{
					paddingTop: ".8rem",
					paddingBottom: ".8rem",
					borderTop: "1px solid  #F2F2F2",
				}}
			>
				<a href={citation?.url} target="_blank" rel="noopener noreferrer" style={{textDecoration:"underline"}}>
					{citation?.title}
				</a>
			</section>
		</main>
	);

	return <GeneralModal title="Citation" content={content} isOpen={isOpen} onClose={onClose} />;
};

export default Citation;
