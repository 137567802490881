import styled from "styled-components";

const Welcome = styled.section`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const Top = styled.div`
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 35px;
`;

const GreetingContainer = styled.div``;

const Greeting = styled.p`
	font-family: "FreightTextProMedium", sans-serif;
	font-size: 28px;
	max-width: 350px;
	font-weight: 400;
`;

const SpanGreeting = styled.p`
	font-family: "CircularStd", sans-serif;
	font-size: 14.5px;
	max-width: 550px;
	font-weight: 400;
`;

const Bottom = styled.section`
	position: relative;
	padding: 50px 16px 0;
	width: 100%;
	height: 100%;
	margin-top: 50px;
	background: transparent;
	display: flex;
	flex-direction: column;
	gap: 18px;
	text-align: center;
	background-color: #fff;
	border-radius: 15px;
`;

const SuggestedPrompts = styled.p`
	text-align: start;
	text-transform: uppercase;
	color: #848484;
`;

const RandomPromptsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 14px;
	width: 100%;
`;

const RandomPrompt = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #3b3b3b;
	cursor: pointer;
	padding: 12px 16px;
	border-radius: 5px;
	width: 100%;
	font-size: 14px;
	background: linear-gradient(
		142.96deg,
		rgba(202, 202, 202, 0.15) -3.54%,
		rgba(160, 160, 160, 0.138) 95.15%
	);
`;

const PromptBoxHome = styled.div`
	position: absolute;
	top: -30px;
	width: 95.7%;
`;

export {
	Welcome,
	Top,
	GreetingContainer,
	Greeting,
	SpanGreeting,
	Bottom,
	SuggestedPrompts,
	RandomPromptsContainer,
	RandomPrompt,
	PromptBoxHome,
};
