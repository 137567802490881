import { create } from "zustand";

export interface BrandState {
	brandLogo: string | null;
	brandColor: string;
	loading: boolean;
	secondaryColor: string;
	quickPrompts:string[];
	setQuickPrompts:(prompts:[])=> void
	setBrandLogo: (brandLogo: string | null) => void;
	setBrandColor: (brandColor: string) => void;
	setSecondaryColor: (color: string) => void;
	setLoading: (loading: boolean) => void;
}

const useBrand = create<BrandState>((set) => ({
	brandLogo: "",
	brandColor: "",
	secondaryColor: "",
	loading: false,
quickPrompts:[],
setQuickPrompts:(prompts:[])=> set({quickPrompts:prompts}),
	setBrandLogo: (brandLogo:string | null) => set({ brandLogo }),
	setBrandColor: (brandColor:string) => set({ brandColor }),
	setSecondaryColor: (color:string) => set({ secondaryColor: color }),
	setLoading: (loading:boolean) => set({ loading }),
}));

export default useBrand;